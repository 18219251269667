import { useEffect, useState } from "react";
import { methodData } from "../httpRequests";
import { backendApiAddress } from "../login/utils/backendApi";
import GridButton from "../components/GridButton";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { RootState } from "../../store/store";
import { setKey } from "../../store/keyslice";
import React from "react";
import Profile from "../header/Profile";
import Button from "../login/components/ui/Button/Button";

function CloudSaves(props: any) {
  const [cloudSaves, setCloudSaves] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const baseURL = `${backendApiAddress}/api`;
    methodData(`${baseURL}/3d/user`, {}, "GET", async (it: any) => {
      const json = await it.json();
      for (let sweater of json) {
        const key = sweater.key;
        console.log(sweater);
        const url = sweater.image.url;
        setCloudSaves((current: any) => {
          return [cloudSaveDiv(key, url), ...current];
        });
      }
      setLoading(false);
    }).catch((it) => setLoading(false));
  }, []);

  const cloudSaveDiv = (key: any, url: any) => {
    return (
      <div
        style={{
          position: "relative",
          aspectRatio: "1",
          pointerEvents: "none",
        }}
        key={key}
      >
        <div
          onClick={() => {
            dispatch(setKey(key));
            props.handleClose();
          }}
          className="showOnHoverMask"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "5px",
            pointerEvents: "auto",
          }}
        ></div>
        <div
          className="showOnHoverComp"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "5px",
            backgroundColor: "rgba(0,0,0,0.5)",
            pointerEvents: "none",
            zIndex: 2,
          }}
        >
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <p
              style={{
                margin: "auto",
                color: "white",
                fontWeight: 500,
              }}
            >
              Open ↗
            </p>
          </div>
        </div>
        <img
          src={url}
          style={{
            position: "absolute",
            backgroundSize: "contain",
            height: "100%",
            width: "100%",
            backgroundColor: "#f9f5f2",
            border: "1px solid #f0f0f0",
            borderRadius: "5px",
            pointerEvents: "none",
          }}
        />
        <p
          style={{
            position: "absolute",
            marginTop: "175px",
            marginLeft: "10px",
            fontWeight: "500",
            fontSize: "14px",
            color: "#977a65",
            pointerEvents: "none",
          }}
        >
          ID: {key}
        </p>
      </div>
    );
  };

  return (
    <div
      className="box"
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "white",
        zIndex: 1,
        display: "flex",
        flexDirection: "column",
        pointerEvents: "auto",
      }}
    >
      <div style={{ display: "flex", height: "5vh", justifyContent: "end" }}>
        <div
          style={{
            marginRight: "35px",
          }}
          className="flex items-center gap-x-5"
        >
          <Profile name={props.name} onClick={props.handleClose} />
        </div>
      </div>
      <div
        className="showScrollbar"
        style={{
          flex: "1",
          overflowY: "auto",
        }}
      >
        <div
          className="showScrollbar"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "15px",
            margin: "15px",
          }}
        >
          {loading && (
            <p
              style={{
                fontSize: "24px",
              }}
            >
              Loading...
            </p>
          )}
          {cloudSaves.length === 0 && "No sweater was found"}
          {cloudSaves}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          gap: "20px",
          marginTop: "20px",
          marginBottom: "50px",
        }}
      >
        <a href="https://design.woolit.no/landing">
          <div
            style={{
              display: "flex",
              borderRadius: "5px",
              height: "50px",
              width: "200px",
              backgroundColor: "#d5ce8a",
            }}
          >
            <p style={{ margin: "auto", fontWeight: "500", color: "black" }}>
              Design Studio Pro ➔
            </p>
          </div>
        </a>
        <Button
          onClick={() => {
            const baseURL = `${backendApiAddress}/api`;
            methodData(`${baseURL}/logout`, {}, "POST", async (it: any) => {
              props.handleClose();
            });
          }}
          style={{
            display: "flex",
            borderRadius: "5px",
            height: "50px",
            width: "200px",
            backgroundColor: "#d5ce8a",
          }}
          variant="quaternary"
        >
          <p style={{ margin: "auto", fontWeight: "500", color: "white" }}>
            Log out
          </p>
        </Button>
      </div>
    </div>
  );
}
export default CloudSaves;
