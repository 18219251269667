import { RGBToRGBString, hexToRgb } from "../knittingpreview/colorutil";

//Global variables: const
export class Settings {
  static startMasksPer10Cm = 18;
  static sizes = ["XS", "S", "M", "L", "XL"];
  static lengthCM = [60, 62, 65, 67, 70] // Kun for display
  static circumfencesCM = [93, 98, 107, 116, 124] // Kun for display
  static circumfences = [168, 176, 192, 208, 224]
  static baseCircumfence = 192
  static shirtTypes = ["raglan", "stitched_sleeves"]
  static maskHeight = Math.round(17 * (16 / 17) * (18 / 23));
  static maskWidth = 16;
  static maxPatternWidth = 36;
  static maxPatternHeight = 36;
  static verbose = true;
  static borderColorOutline = "#B4917B";
  static borderColorOutlineSnap = "#F1C40F";
  static borderColorOutlineRGBstring = RGBToRGBString(
    hexToRgb(this.borderColorOutline)
  );
  static borderColorOutlineSnapRGBstring = RGBToRGBString(
    hexToRgb(this.borderColorOutlineSnap)
  );
  static defaultPatternHeightVH = 20 * (18/23);

  static startingColors = [2, 32, 5, 31, 27];
  static colorsAllNames = [
    "Grey mottled",
    "Light grey-brown mottled",
    "White",
    "Nature",
    "Red terracotta",
    "Red",
    "Burgundy",
    "Deep sea green",
    "Petrol",
    "Spring green",
    "Ivy green",
    "Yew green",
    "Denim blue",
    "Marine",
    "Ice pink",
    "Mild rose",
    "Coral",
    "Light denim",
    "Jeans blue",
    "Sea green",
    "Beige rose",
    "Copper",
    "Dark rose",
    "Jade green",
    "Honey yellow",
    "Old pink",
    "Peony red",
    "Thuja green",
    "Camel",
    "Warm brown",
    "Grey-brown",
    "Clear blue",
    "Coal grey",
    "Metallic brown",
    "Nut brown",
    "Mole",
    "Powder",
    "Light yellow",
    "Powder blue",
    "Mint",
  ];
  static colorsAll = [
    "#97948f",
    "#bfb9b4",
    "#e8e3da",
    "#ded9d4",
    "#af5e49",
    "#6a242f",
    "#612d38",
    "#457174",
    "#3a5259",
    "#8f885c",
    "#3b4135",
    "#3a463e",
    "#515964",
    "#363948",
    "#d0b1c3",
    "#ba8e8f",
    "#cd8a7e",
    "#8e919d",
    "#7d97a3",
    "#799f9e",
    "#c89989",
    "#925947",
    "#967876",
    "#9aa392",
    "#b4915b",
    "#8e5859",
    "#754a4f",
    "#74968f",
    "#b39c85",
    "#5a4436",
    "#4f4543",
    "#335583",
    "#333333",
    "#634e43",
    "#977b60",
    "#665a56",
    "#e3ccbc",
    "#f5e4b0",
    "#a8b3b3",
    "#d0e4d4",
  ]; //Must be lowercase

  static colorsAllID = [
    "229-301",
    "229-302",
    "229-303",
    "229-304",
    "229-306",
    "229-307",
    "229-308",
    "229-311",
    "229-312",
    "229-313",
    "229-314",
    "229-315",
    "229-316",
    "229-317",
    "229-318",
    "229-319",
    "229-321",
    "229-322",
    "229-323",
    "229-324",
    "229-325",
    "229-326",
    "229-327",
    "229-328",
    "229-329",
    "229-330",
    "229-331",
    "229-332",
    "229-333",
    "229-334",
    "229-335",
    "229-336",
    "229-337",
    "229-338",
    "229-339",
    "229-340",
    "229-342",
    "229-343",
    "229-344",
    "229-345",
  ]

  static version = "v6";
  static versionStorageName = "version";
  static sizeIndexStorageName = "size";
  static knittingMethodIndexStorageName = "knittingMethod";
  static sweaterStorageName = "sweater";
  static patternStorageName = "pattern_v5"; // _v5 Since I dont want the users to lose these patterns
  static colorsStorageName = "colors";

  static knitabilityWarningColor = "#f1c40f";
  static knitabilityWarningColorDisplay = "#FBAE3B"; //text display on white background
  static knitabilityErrorColor = "#ff0000";
  static emphasisColor = "#cc4e00";

  static limitMirror = true; //false is missing: delete - areagroup -> selected only
  static limitRepeat = true;

  static mirrorArmsDifferently = true;

  static totalYarnSweater = 12;
}
